import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from '@shared/services/users-service';
import { AlertService } from '@shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceService } from '@shared/services/auth-service.service';
import { of } from 'rxjs';

export const userPermissionsResolver: ResolveFn<any> = (route, state) => {
  const cookieService = inject(CookieService);
  const usersService = inject(UsersService);
  const alertService = inject(AlertService);
  const translateService = inject(TranslateService);
  const authService = inject(AuthServiceService);

  let urlUserId = route.paramMap.get('userId');

  if(cookieService.check('user_permissions') && cookieService.check('user_role') && cookieService.check('terms') && cookieService.get('terms') === 'accepted'
 &&  cookieService.check('habbeas') && cookieService.get('habbeas') === 'accepted'){
    return true;
  }

  authService.isUserAuthenticated(cookieService.get('access_token')).then(response=>{
    if(response){
      usersService.getUserData(urlUserId).subscribe({
        next:(response)=>{
          cookieService.set('user_permissions',JSON.stringify(response['result'][0]['permissions']),{path:'/'});
          cookieService.set('user_role',JSON.stringify(response['result'][0]['role']),{path:'/'});
          let terms = response['result'][0]['terms_and_conditions'];
          let habbeas = response['result'][0]['habeas_data']
          if(terms){
            cookieService.set('terms','accepted',{path:'/'})
          }else{
            cookieService.set('terms','not_accepted',{path:'/'})
          }

          if(habbeas){
            cookieService.set('habbeas','accepted',{path:'/'})
          }else{
            cookieService.set('habbeas','not_accepted',{path:'/'})
          }
          
          return of(true);
        },
        error:(error)=>{
          translateService.get('others.httpErrorMessages.userPermissions').subscribe((translation: string) => {
            const alertMessage = translation;
            alertService.emitAlert(alertMessage, 'WARNNING', error);
          });
        }
      })
    }
  })

  
  return of(true);

  
};
