// Home app ts logic
// Created by: Christian David Sierra Martinez
// Improvements: ---

// Imports =====================================================================================================
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowUpRightFromSquare,IconDefinition } from '@fortawesome/free-solid-svg-icons';

// Interfaces/models ===========================================================================================
import { IUser } from '@core/models/IUser';


// Selectors and component unit blocks =========================================================================
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.css'
})

export class HomePageComponent implements OnInit{
  //General Properties ========================================================================================
  public isToolbarOpen:boolean=false;
  public profileIcon:IconDefinition=faArrowUpRightFromSquare;
  public defaultUserProfileImg:string='assets/svgs/miner-svgrepo-com.svg';
  public userData:any={};
  public profileImage:any={};
  public minerPng:string='assets/minerImages/minerSvgs/noOutlinev1.png';
  public userImage:string='/assets/generalImages/otherImages/defaultUserImage.png';
  public defaultUserImage:string='/assets/generalImages/otherImages/defaultUserImage.png';
  public loaderSvg:string='/assets/svgs/ripples.svg';
  public showUserImage:boolean=false;

  constructor(private router:Router, private activatedRoute:ActivatedRoute){}

  // LifeCycles ================================================================================================
  ngOnInit(): void {

    this.activatedRoute.data.subscribe({
      next:(response)=>{
        try{
          
          if(response && response['userData']){
            let userData=response['userData']['profileGeneralData'];
            let profileImage=response['userData']['signedUrl'];

            if (userData && userData['result'] && userData['result'][0]) {
              this.userData = userData['result'][0];
            }
          
            if (profileImage && profileImage['signedUrl']) {
              this.userImage = profileImage['signedUrl'];
            }
          }
        }catch(error){
          console.log(error)
        }
      },
      error:(error)=>{
        console.log('Error resolving some data on profile, going to defaults');
        console.log(error);
      }
    });
  }

  // CustomFuntions ============================================================================================
  public closeToolbar():void{
    this.isToolbarOpen = !this.isToolbarOpen
  }

  public navToProfile():void{
    if(this.isToolbarOpen){
      this.router.navigate(['/','home','profile']);
    }

  
  }

  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.defaultUserImage;
  }
}
