<!-- Home app html -->
<!-- Created by: Christian David Sierra Martinez -->
<!-- Improvements/changes:--- -->
<!-- NOTE: Sidebar will never be destroyed -->

<div class="home-container">
    <!-- Toolbar container styling -->
    <div class="home-toolbar-container" [ngClass]="isToolbarOpen ? 'open' : 'close'">
        <!-- Toolbar profile styling -->
        <div class="home-toolbar-profile-container">
            <div class="home-toolbar-profile" (click)="navToProfile()">
                <div class="home-toolbar-profile-img">
                    <img class="media-object mw150 user-placeholder-image" [src]="loaderSvg" alt="userPhoto" loading="lazy" *ngIf="!showUserImage">
                    <img class="media-object mw150" [src]="userImage" alt="userPhoto" (load)="showUserImage = true" [ngStyle]="{'display': !showUserImage ? 'hidden' : 'block'}" #profilePictureHolder (error)="handleImageError($event)" loading="lazy">
                </div>


                <div class="d-flex flex-column profile-data">
                    <p class="profile-name-toolbar">{{userData?.first_name}} {{userData?.surname}}</p>
                    <div>
                        <small>{{'toolbar.options.profile' | translate}}</small>
                        <fa-icon class="fa-icon-medium ms-2" [icon]="profileIcon"></fa-icon>
                    </div>
                    
                </div>

            </div>
            <div class="home-profile-separator"></div>
        </div>
        <!-- Toolbar options component styling -->
        <app-tools-bar class="home-toolbar-options" [isToolbarOpne]="isToolbarOpen"></app-tools-bar>
        <!-- Toggle button to open and close sidebar -->
        <div class="toolbar-open-close" [ngClass]="isToolbarOpen ? 'open' : 'close'" (click)="closeToolbar()">
            <mat-icon>arrow_forward_ios</mat-icon>
        </div>
    </div>
    <!-- Home content outlet -->
    <div class="home-outlet-container">
        <router-outlet></router-outlet>
    </div>
</div>